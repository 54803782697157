<template>
    <nav class="navbar">
      <img src="../assets/LOGO_SIN_FONDO.png" alt="logo" @click="toHome">
      <ul>
        <li ><RouterLink :to="{name: 'home'}">Inicio</RouterLink></li> 
        <li><RouterLink :to="{name: 'productos'}">Diseños</RouterLink></li>
        <li><RouterLink :to="{name: 'conocenos'}">Conocenos</RouterLink></li>
        <li><RouterLink :to="{name: 'contacto'}"> Contacto</RouterLink></li>
      </ul>
    </nav>
  </template>
  
  <style scoped>
  .navbar {
    height: 145px;
    width: 100%;
    background-color: #ebebeb;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 4px double #96BE0E;
    align-items: center;
  }

img {
  height: 105px;
  width: auto;
  object-fit: contain;
  margin-left: 60px;
cursor: pointer;
}


ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 80px;
  
}


ul li {
  padding: 5px;
  list-style: none;
  
}



ul li a {
  color: #575555;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  margin-right: 10px;
  font-family: 'Mulish', sans-serif;
  border: none;
  
}

ul li a:hover {
  color: #96BE0E;
}



button {
padding: 16px;
border-radius: 40px;
margin-right: 20px;
font-size: 14px;
color: #fff;
background-color: #128C7E;
font-weight: bold;
transition: 0.5s;
}

button:hover {
  background-color: #fff;
  color: #3a3a3a;
  transform: translateY(-8px);
  transition: 0.5s;
}




  </style>
  

<script >
 
export default {
  methods: {
    toHome() {
            this.$router.push('/')
        }
  }
}

</script>