<template>
  
    <div  class="nav-mobile">
        <i class="fas fa-bars barra" @click="openNav"></i>
        <img src="../assets/LOGO_SIN_FONDO.png" alt="logo" @click="toHome">
        
    
    </div>
    
    <nav class="navbar-mobile"  :class="{ open: showNav }">
        <div class="container">
            <div class="box"> <i class="fa-solid fa-arrow-left equis" @click="closeNav"></i> </div>
           
      <ul>
        <li ><RouterLink :to="{name:'home'}" @click="closeNav">Inicio</RouterLink></li> 
        <li><RouterLink :to="{name: 'productos'}" @click="closeNav">Diseños</RouterLink></li>
        <li><RouterLink :to="{name: 'conocenos'}" @click="closeNav">Conocenos</RouterLink></li>
        <li><RouterLink :to="{name: 'contacto'}" @click="closeNav"> Contacto</RouterLink></li>
        <hr>
    </ul>
       
        <div class="icons">
      <button @click="toWp"><i class="fa-brands fa-whatsapp wp"></i></button>
      <button @click="toFb"><i class="fa-brands fa-facebook fb"></i></button>
      <button @click="toIg"><i class="fa-brands fa-instagram ig"></i></button>
    </div>
        
    <img src="../assets/LOGO_SIN_FONDO.png">
        </div>
       
    </nav>
    <div class="space"></div>
</template>



<style scoped>

* {
    overflow: hidden;
}

.nav-mobile {
height: 80px;
background-color: #f4f4f4;
width:100%;
border-bottom: 2px solid #96BE0E;;
display: flex;
justify-content: space-between;
align-items: center;
position: fixed;
top: 0;
left: 0;
    right: 0;
z-index: 300;
}


.nav-mobile img {
    margin:0 auto;
    height: 60px;
    width: auto;
}

.space {
    margin-bottom: 120px;
}
.navbar-mobile {
    position: absolute;
    top: 0;
    left: 0;
   min-height: 54%;
    width: 300px;
    background-color: #f4f4f4;
    overflow:hidden;
    transition: 0.5s;
    transform: translateX(-400px);
    position: fixed;
    z-index: 4000;
    border-radius: 0 40px 40px 0;
    box-shadow: 2px 2px 1px #96BE0E;
}

.open{
    animation: open 0.3s linear  ;
    animation-fill-mode: forwards;
    transform: 0.5s;
    
}


@keyframes open {
    0% {
       
    }
    100% {
        transform: translateX(0);
    }
}



i {
  font-size: 2rem;
  
}

.barra {
    margin-left: 40px;
    margin-top: 4px;
    
    padding: 8px;
    color:#96BE0E;
}



img {
    height: auto;
    width: 180px;
} 

.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
    gap: 20px;
}

.container .box {
    height: 70px;
    width: 100%;
background-color: #96BE0E;
}

.container .box .equis {
position: absolute;
top: 20px;
right: 30px;
font-size: 2rem;
color: #fff;

}

.container ul {
    
    margin-bottom: 14px;
}

.container ul li {
    list-style: none;
    margin-bottom: 6px;
    
    border-top: 1px solid #51545c;
}



.container ul li a {
    text-decoration: none;
    color: #575555;
    font-size: 18px;
    margin-left: 20px;
    
}




 .container .icons {
    display: flex;
    gap: 24px;
    margin-bottom: 20px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    
 }

 .container .icons button {
    padding: 6px 8px;
    border-radius: 30px;
    color: #51545c;
    background-color: #f4f4f4;
    border: none;
    cursor: pointer;
 }

 hr {
    border: 1px solid #51545c;
  border-radius: 5px;
 }

 .container img {
    margin: 30px auto 0;
    height: 120px;
     width: auto;
     padding-bottom: 30px;
 }


</style>


<script>

export default {
    data: () => {
        return {
            showNav: false
        }
    },
    methods: {
        openNav() {
            this.showNav = true
        },
        closeNav() {
            this.showNav = false
        },
        toHome() {
            this.$router.push('/')
        },
        toWp() {
      window.open('https://api.whatsapp.com/send?phone=542664728928', '_blank');
    },
    toIg() {
      window.open('https://www.instagram.com/muebles_san_luis/?hl=es-la', '_blank');
    },
    toFb() {
      window.open('https://www.facebook.com/MueblesdecocinaSLJuanaKoslay', '_blank');
    }
    }
}


</script>