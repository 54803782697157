import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    meta: { title: 'Muebles San Luis - Muebles a medida' }
    
  },
  {
    path: '/productos',
    name: 'productos',
    component: () => import(/* webpackChunkName: "productos" */ '../components/Productos.vue'),
    meta: { title: 'Productos' }
  },

{
  path: '/banos',
  name: 'banos',
  component: () => import(/* webpackChunkName: "banos" */ '../components/Banos.vue'),
  meta: { title: 'Baños' }
},
{
  path: '/otros',
  name: 'otros',
  component: () => import(/* webpackChunkName: "otros" */ '../components/Otros.vue'),
  meta: { title: 'Otros' }
}
  ,
  {
    path: '/dormitorio',
    name: 'dormitorio',
    component: () => import(/* webpackChunkName: "dormitorios" */ '../components/Dormitorios.vue'),
    meta: { title: 'Dormitorio' }
  }
    ,
    {
      path: '/vestidores',
      name: 'vestidores',
      component: () => import(/* webpackChunkName: "vestidores" */ '../components/Vestidores.vue'),
      meta: { title: 'Vestidores' }
    }
      ,
      {
        path: '/cocina',
        name: 'cocina',
        component: () => import(/* webpackChunkName: "cocina" */ '../components/Cocina.vue'),
        meta: { title: 'Cocina' }
      }
        ,
      {
        path: '/placards',
        name: 'placards',
        component: () => import(/* webpackChunkName: "cocina" */ '../components/Placards.vue'),
        meta: { title: 'Placards' }
      }
        ,
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "otros" */ '../components/Contacto.vue'), 
    meta: { title: 'Contacto' }
  },
  {
    path: '/conocenos',
    name: 'conocenos',
    component: () => import(/* webpackChunkName: "otros" */ '../components/Conocenos.vue'),
    meta: { title: 'Conocenos' }
  },
  { 
    path: '/:pathMatch(.*)*', 
    component: () => import(/* webpackChunkName: "NoPageFound" */ '@/components/NoPageFound.vue'),
    meta: { title: 'Error' }
},

,

]



const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});






export default router
