<template>
 
  <div id="navigation-icon" v-if="mobileView">
    <NavbarMobile ></NavbarMobile>
  </div>
  <Navbar v-if="!mobileView"></Navbar>
  <router-view/>
  
</template>

<style scoped>
#navigation-icon {
 height: 100%;
  margin-top: 0;
  cursor: pointer;
}

html, body {
  height: 100%;
}




</style>

<script>

import Navbar from './components/Navbar.vue';
import NavbarMobile from './components/NavbarMobile.vue'


export default {
components: {
  Navbar,
  NavbarMobile,
  
},
data:() => {
    return {
      mobileView: true,
      
    }
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 1000
    }
  },
  created() {
    this.handleView();
    window.addEventListener('resize', this.handleView);

  }

}

</script>

